import React, {useState, useEffect} from 'react';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import AppointmentTab from './../Element/AppointmentTab';
import axios from "axios";
import Pusher from "pusher-js";
let bnr = require("./../../images/banner/bnr1.jpg");
const pusher = new Pusher('eab6680e0ed61ddc8b28', {
	cluster: 'eu'
});
const channel = pusher.subscribe('my-channel');
const OnlineAppointmentPage = (props) => {
	const [onlineAppointmentState, setOnlineAppointmentState] = useState('0')
	const [businessName, setBusinessName] = useState('')
	const [businessId, setBusinessId] = useState('')
	const [defaultService, setDefaultService] = useState('')
	const [defaultTeammate, setDefaultTeammate] = useState('')
	const [islemSuresi, setIslemSuresi] = useState(15)
	const [services, setServices] = useState([])
	const [teammates, setTeammates] = useState([])
	const [appointments, setAppointments] = useState([])
	const [customerName, setCustomerName] = useState('0')
	const [customerEmail, setCustomerEmail] = useState('0')
	const [onlineAppointmentInfos, setOnlineAppointmentInfos] = useState({})
	channel.bind('my-event', async function(data) {
		getBusinessInfosForOnlineAppointmentPageOnlyWebSocket()
	});
	const getBusinessInfosForOnlineAppointmentPage = () => {
		axios.post('https://api.appomark.com/api/auth/get-business-infos-for-online-appointment-page', {
			url: props.match.params.name
		}).then((response) => {
			if(response.data.code === 1){
				let onlineAppointmentData = {
					gmbAddress: response.data.data['business'].yorumLink.split('/review')[0],
					address: response.data.data['business'].online_appointment_address,
					phone: response.data.data['business'].gmbPhone,
					webSite: response.data.data['business'].online_appointment_web_site,
					email: response.data.data['business'].online_appointment_email,
					iframe: response.data.data['business'].online_appointment_iframe,
					mondayStart: response.data.data['working_hours'].monday.start,
					mondayEnd: response.data.data['working_hours'].monday.end,
					tuesdayStart: response.data.data['working_hours'].tuesday.start,
					tuesdayEnd: response.data.data['working_hours'].tuesday.end,
					wednesdayStart: response.data.data['working_hours'].wednesday.start,
					wednesdayEnd: response.data.data['working_hours'].wednesday.end,
					thursdayStart: response.data.data['working_hours'].thursday.start,
					thursdayEnd: response.data.data['working_hours'].thursday.end,
					fridayStart: response.data.data['working_hours'].friday.start,
					fridayEnd: response.data.data['working_hours'].friday.end,
					saturdayStart: response.data.data['working_hours'].saturday.start,
					saturdayEnd: response.data.data['working_hours'].saturday.end,
					sundayStart: response.data.data['working_hours'].sunday.start,
					sundayEnd: response.data.data['working_hours'].sunday.end
				}
				setOnlineAppointmentInfos(onlineAppointmentData)
				setOnlineAppointmentState(response.data.data['business'].online_appointment_state === 1 ? '1' : '0')
				setBusinessId(response.data.data['business'].id)
				setBusinessName(response.data.data['business'].businessName)
				setIslemSuresi(response.data.data['business'].islemSuresi)
				setServices(response.data.data['services'])
				setTeammates(response.data.data['teammates'])
				setDefaultService(response.data.data['services'].find(item => item.is_default_service === 1).service_name)
				setDefaultTeammate(response.data.data['teammates'].find(item => item.is_default_teammate === 1).teammateName)
				setAppointments(response.data.data['appointments'])
			}
			else{
				window.location = '/'
			}
		}).catch(err => console.log(err))
	}
	const getBusinessInfosForOnlineAppointmentPageOnlyWebSocket = () => {
		axios.post('https://api.appomark.com/api/auth/get-business-infos-for-online-appointment-page', {
			url: props.match.params.name
		}).then((response) => {
			if(response.data.code === 1){
				setAppointments(response.data.data['appointments'])
			}
			else{
				window.location = '/'
			}
		}).catch(err => console.log(err))
	}
	const loadData = () => {
		getBusinessInfosForOnlineAppointmentPage()
	}
	const controlPhoneNumber = (phone) => {
		if(phone === '0'){
			setCustomerName('0')
		}
		else{
			axios.post('https://api.appomark.com/api/auth/get-customer-infos-for-online-appointment-page', {
				phone: phone,
				url: props.match.params.name
			}).then((response) => {
				if(response.data.code === 1){
					setCustomerName(response.data.data.customerFullName)
					setCustomerEmail(response.data.data.customerEmail)
				}else{
					setCustomerName('1')
					setCustomerEmail('1')
				}
			}).catch(err => console.log(err))
		}
	}
	useEffect(() => {
		loadData();
	}, [])
	return(
		<div className="page-wraper">
			<Header />
			<div className="page-content bg-white text-gray-1">
				<div className="dlab-bnr-inr dlab-bnr-inr-sm overlay-black-middle inner-particles"  style={{backgroundImage:"url(" + bnr + ")", height: '50px'}} >
					<div className="container">
					</div>
				</div>
				<div className="listing-details-head">
					<div className="container">
						<div className="listing-info-box">
							<div className="listing-info">
								<h2 className="title">{businessName}</h2>
							</div>
						</div>
					</div>
				</div>
				{onlineAppointmentState && services && teammates && customerName && <AppointmentTab 
					onlineAppointmentState={onlineAppointmentState}
					onlineAppointmentInfos={onlineAppointmentInfos}
					services={services} 
					businessId={businessId}
					teammates={teammates} 
					defaultService={defaultService} 
					defaultTeammate={defaultTeammate} 
					islemSuresi={islemSuresi} 
					appointments={appointments}
					controlPhone={(controlPhone, phone) => controlPhone && controlPhoneNumber(phone)}
					customerName={customerName}
					customerEmail={customerEmail}
				/>}
			</div>
			<Footer />
		</div>
	)
}

export default OnlineAppointmentPage;