import React, { Component } from 'react';

class Footer extends Component {
	render() {
		return (
			<footer className="site-footer">
				<div className="footer-top">
					<div className="container">
						<div className="row">
							<div className="col-xl-5 col-lg-4 col-md-12 col-sm-12">
								<div className="widget">
									<img src={require("./../../images/sadelogowhitetextsite.png")} className="m-b15" alt="" width="180" />
									<p className="text-capitalize m-b20">Appomark: Appointment and Marketing Software</p>
									{/* <ul className="list-inline social-icon  m-a0">
										<li><a className="site-button facebook circle " href="https://www.facebook.com/saderandevu/"><i className="fa fa-facebook"></i></a></li>
										<li><a href="https://www.instagram.com/saderandevu/" className="site-button instagram circle "><i className="fa fa-instagram"></i></a></li>
										<li><a href="https://twitter.com/saderandevu/" className="site-button twitter circle "><i className="fa fa-twitter"></i></a></li>
									</ul> */}
								</div>
							</div>
							<div className="col-xl-4 col-lg-5 col-md-7 col-sm-12 col-12">
								<div className="widget border-0">
									<h5 className="m-b30 text-white">Quick Links</h5>
									<ul className="list-2 list-line">
										<li><a href="https://appomark.com">Software</a></li>
										<li><a href="https://platform.appomark.com">Business Login</a></li>
									</ul>
								</div>
							</div>
							<div className="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12">
								<div className="widget widget_getintuch">
									<h5 className="m-b30 text-white">Contact Us</h5>
									<ul>
										<li><i className="ti-location-pin"></i><strong>Address</strong> 31 NE 17th St, Miami, FL 33132</li>
										<a href="mailto:info@appomark.com"><li><i className="ti-email"></i><strong>email</strong>info@appomark.com</li></a>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
		)
	}
}

export default Footer;