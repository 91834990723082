import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import axios from "axios";

const AppointmentTabContent = (props) => {
	const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
	const work_days = {
		monday: { start: props.onlineAppointmentInfos.mondayStart, end: props.onlineAppointmentInfos.mondayEnd },
		tuesday: { start: props.onlineAppointmentInfos.tuesdayStart, end: props.onlineAppointmentInfos.tuesdayEnd },
		wednesday: { start: props.onlineAppointmentInfos.wednesdayStart, end: props.onlineAppointmentInfos.wednesdayEnd },
		thursday: { start: props.onlineAppointmentInfos.thursdayStart, end: props.onlineAppointmentInfos.thursdayEnd },
		friday: { start: props.onlineAppointmentInfos.fridayStart, end: props.onlineAppointmentInfos.fridayEnd },
		saturday: { start: props.onlineAppointmentInfos.saturdayStart, end: props.onlineAppointmentInfos.saturdayEnd },
		sunday: { start: props.onlineAppointmentInfos.sundayStart, end: props.onlineAppointmentInfos.sundayEnd }
	};
	const [startDate, setStartDate] = useState(new Date());
	const [personel, setPersonel] = useState(props.defaultTeammate);
	const [service, setService] = useState(props.defaultService);
	const [saat, setSaat] = useState('0');
	const [not, setNot] = useState('')
	const [phone, setPhone] = useState('0')
	const [kayitsizCustomerFullName, setKayitsizCustomerFullName] = useState('')
	const [kayitsizCustomerEmail, setKayitsizCustomerEmail] = useState('')
	const [disableButton, setDisableButton] = useState(0)
	const [appointmentsArrayForHours, setAppointmentArrayForHours] = useState([])
	const onChangePersonel = (selectedPersonel) => {
		setPersonel(selectedPersonel.target.value)
		setSaat('0')
	}
	const onChangeHizmet = (selectedHizmet) => {
		setService(selectedHizmet.target.value)
		setSaat('0')
	}
	const formatDate = (date) => {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2)
			month = '0' + month;
		if (day.length < 2)
			day = '0' + day;

		return [year, month, day].join('-');
	}
	function dateDiffInHours(a, b) {
		let diff = Math.abs(a - b);
		return Math.floor((diff / 1000) / 60);
	}
	useEffect(() => {
		appointmentCalendarPrepare(startDate);
	}, [props.appointments])
	const appointmentCalendarPrepare = (date) => {
		console.log('appCalPrepare Başladı', date)
		let appointmentsArray = [];
		props.appointments.forEach((item) => {
			if (formatDate(date) === formatDate(new Date(item.appointmentTime))) {
				let difference = dateDiffInHours(new Date(item.appointmentEndTime), new Date(item.appointmentTime));
				let addObj = {
					id: item.id,
					startDate: item.appointmentTime,
					endDate: item.appointmentEndTime,
					insideDates: [],
					teammateID: item.teammateID,
					serviceID: item.service_id
				}
				let eklenecekMiktarAdedi = difference / 15;
				for (let i = 0; i < eklenecekMiktarAdedi; i++) {
					let newDateObj = new Date();
					newDateObj.setTime(new Date(item.appointmentTime).getTime() + (i * 15 * 60 * 1000))
					addObj.insideDates.push(newDateObj)
				}
				appointmentsArray.push(addObj);
			}
		})
		setAppointmentArrayForHours(appointmentsArray)
	}
	const displayHour = (hour, minute, date) => {
		var selectedDayStartHour = work_days[days[new Date(date).getDay()]].start.split(':')[0]
		var selectedDayStartMinute = work_days[days[new Date(date).getDay()]].start.split(':')[1]
		var selectedDayEndHour = work_days[days[new Date(date).getDay()]].end.split(':')[0]
		var selectedDayEndMinute = work_days[days[new Date(date).getDay()]].end.split(':')[1]

		if (hour == selectedDayStartHour && minute < selectedDayStartMinute) {
			return false
		}

		if (hour == selectedDayEndHour && minute >= selectedDayEndMinute) {
			return false
		}

		if (hour <= selectedDayEndHour && hour >= selectedDayStartHour) {
			return true;
		}
		else {
			return false;
		}
	}
	const returnDisabledHour = (hour, minute, date) => {
		if (new Date().getMonth() === new Date(date).getMonth() && new Date().getDate() === new Date(date).getDate()) {
			// demek ki bu gün tarihindeyiz önceki saatleri toparla
			if (hour <= new Date().getHours()) {
				return true;
			}
			else {
				return appointmentsArrayForHours.find(item => {
					if (new Date(item.startDate).getDate() === startDate.getDate()) {
						if (personel === '') {
							if (item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id) {
								return item.insideDates.find((insideDate) => {
									return new Date(insideDate).getHours() === hour && new Date(insideDate).getMinutes() === minute
								}) !== undefined
							}
						}
						else {
							if (item.teammateID === parseInt(personel)) {
								return item.insideDates.find((insideDate) => {
									return new Date(insideDate).getHours() === hour && new Date(insideDate).getMinutes() === minute
								}) !== undefined
							}
						}
					}
				}) !== undefined
			}
		}
		else {
			//demek ki ileri tarihteyiz normal returnle
			return appointmentsArrayForHours.find(item => {
				if (new Date(item.startDate).getDate() === startDate.getDate()) {
					if (personel === '') {
						if (item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id) {
							return item.insideDates.find((insideDate) => {
								return new Date(insideDate).getHours() === hour && new Date(insideDate).getMinutes() === minute
							}) !== undefined
						}
					}
					else {
						if (item.teammateID === parseInt(personel)) {
							return item.insideDates.find((insideDate) => {
								return new Date(insideDate).getHours() === hour && new Date(insideDate).getMinutes() === minute
							}) !== undefined
						}
					}
				}
			}) !== undefined
		}
	}
	const iframe = () => {
		return {
			__html: props.onlineAppointmentInfos.iframe
		}
	}
	function convertDateForIos(date) {
		let arr = date.split(/[- :]/);
		date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
		return date;
	}
	function returnOption(hour, minute, date, doubleHour, doubleMinute, amPm) {
		const valueGidecekHour = doubleHour
		const valueGidecekMinute = doubleMinute

		doubleHour = doubleHour >= 12 ?
			doubleHour == 12 ? 12 : doubleHour == 13
				? 1 : doubleHour == 14
					? 2 : doubleHour == 15
						? 3 : doubleHour == 16
							? 4 : doubleHour == 17
								? 5 : doubleHour == 18
									? 6 : doubleHour == 19
										? 7 : doubleHour == 20
											? 8 : doubleHour == 21
												? 9 : doubleHour == 22
													? 10 : 11
			: doubleHour
		return (<option
			style={
				displayHour(hour, minute, date) === true ?
					returnDisabledHour(hour, minute, startDate) === true
						? { backgroundColor: 'red', color: 'white' }
						:
						{ backgroundColor: 'green', color: 'white' }
					: { display: 'none' }
			}
			disabled={returnDisabledHour(hour, minute, date)}
			value={valueGidecekHour + ':' + valueGidecekMinute}
		>{doubleHour}:{doubleMinute} {amPm}</option>)
	}
	return (
		<div className="row">
			<div className="col-xl-8 col-lg-7 col-md-12">
				<div className="content-box">
					<div className="content-header">
						<h3 className="title"><i className="la la-bullhorn m-r5"></i>Make Online Appoinment</h3>
					</div>
					<div className="content-body">
						{props.onlineAppointmentState === '1'
							? <div className="clear" id="comment-list">
								<div className="comments-area" id="comments">
									<div className="comment-form" id="commentform" >
										<iframe src={"https://api.appomark.com/appointments/create/" + window.location.href.split('/').pop()} width="700" height="400"></iframe>
									</div>
								</div>
							</div>
							: <h2>This business's Online Appointment feature is turned off. Please contact them through their contact information!</h2>
						}
					</div>
				</div>
				<div className="content-box">
					<div className="content-header">
						<h3 className="title"><i className="la la-file-text m-r5"></i> Working Hours</h3>
					</div>
					<div className="content-body collapse show" id="collapseOne" aria-labelledby="headingOne" data-parent="#accordion">
						<ul className="listing-time-list">
							<li><span className="listing-day">Monday</span> <span className="listing-hours">{props.onlineAppointmentInfos.mondayStart == props.onlineAppointmentInfos.mondayEnd ? 'Off' : props.onlineAppointmentInfos.mondayStart + '-' + props.onlineAppointmentInfos.mondayEnd}</span></li>
							<li><span className="listing-day">Tuesday</span> <span className="listing-hours">{props.onlineAppointmentInfos.tuesdayStart == props.onlineAppointmentInfos.tuesdayEnd ? 'Off' : props.onlineAppointmentInfos.tuesdayStart + '-' + props.onlineAppointmentInfos.tuesdayEnd}</span></li>
							<li><span className="listing-day">Wednesday</span> <span className="listing-hours">{props.onlineAppointmentInfos.wednesdayStart == props.onlineAppointmentInfos.wednesdayEnd ? 'Off' : props.onlineAppointmentInfos.wednesdayStart + '-' + props.onlineAppointmentInfos.wednesdayEnd}</span></li>
							<li><span className="listing-day">Thursday</span> <span className="listing-hours">{props.onlineAppointmentInfos.thursdayStart == props.onlineAppointmentInfos.thursdayEnd ? 'Off' : props.onlineAppointmentInfos.thursdayStart + '-' + props.onlineAppointmentInfos.thursdayEnd}</span></li>
							<li><span className="listing-day">Friday</span> <span className="listing-hours">{props.onlineAppointmentInfos.fridayStart == props.onlineAppointmentInfos.fridayEnd ? 'Off' : props.onlineAppointmentInfos.fridayStart + '-' + props.onlineAppointmentInfos.fridayEnd}</span></li>
							<li><span className="listing-day">Saturday</span> <span className="listing-hours">{props.onlineAppointmentInfos.saturdayStart == props.onlineAppointmentInfos.saturdayEnd ? 'Off' : props.onlineAppointmentInfos.saturdayStart + '-' + props.onlineAppointmentInfos.saturdayEnd}</span></li>
							<li><span className="listing-day">Sunday</span> <span className="listing-hours">{props.onlineAppointmentInfos.sundayStart == props.onlineAppointmentInfos.sundayEnd ? 'Off' : props.onlineAppointmentInfos.sundayStart + '-' + props.onlineAppointmentInfos.sundayEnd}</span></li>
						</ul>
					</div>
				</div>
			</div>
			<div className="col-xl-4 col-lg-5 col-md-12">
				{/*kaymasını istiyorsan üste sticky-top classı ekle*/}
				<aside className="side-bar listing-side-bar">
					<div className="content-box">
						<div className="content-header">
							<h3 className="title"><i className="la la-file-text m-r5"></i> Contact</h3>
						</div>
						<div className="content-body">
							<ul className="icon-box-list">
								<li><a style={{ color: '#555' }} target="_blank" href={props.onlineAppointmentInfos.gmbAddress}>
									<div className="icon-cell bg-gray">
										<i className="la la-map-marker"></i>
									</div>
									<span>{props.onlineAppointmentInfos.address}</span>
								</a>
								</li>
								<li><a style={{ color: '#555' }} href={"tel:" + props.onlineAppointmentInfos.phone}>
									<div className="icon-cell bg-gray">
										<i className="la la-phone"></i>
									</div>
									<span>{props.onlineAppointmentInfos.phone}</span>
								</a></li>
								<li><a style={{ color: '#555' }} target="_blank" href={props.onlineAppointmentInfos.webSite}>
									<div className="icon-cell bg-gray">
										<i className="la la-globe"></i>
									</div>
									<span>{props.onlineAppointmentInfos.webSite && props.onlineAppointmentInfos.webSite.substring(0, 20) + '...'}</span>
								</a></li>
								<li><a style={{ color: '#555' }} href={"mailto:" + props.onlineAppointmentInfos.email}>
									<div className="icon-cell bg-gray">
										<i className="la la-envelope"></i>
									</div>
									<span>{props.onlineAppointmentInfos.email}</span>
								</a></li>
							</ul>
						</div>
					</div>
					<div className="content-box">
						<div className="content-header">
							<h3 className="title"><i className="la la-map-marker m-r5"></i>Map</h3>
						</div>
						<div className="content-body">
							<div className="align-self-stretch" >
								<div dangerouslySetInnerHTML={iframe()} />
							</div>
						</div>
					</div>
				</aside>
			</div>
		</div>
	)
}

export default AppointmentTabContent;