import React, { Component } from 'react';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
let bnr = require('./../../images/banner/bnr3.jpg');
class HomePage extends Component {
	render() {
		return (
			<div className="page-wraper">
				<Header />
				<div className=" bg-white">
					<div className="dlab-bnr-inr dlab-bnr-inr-sm overlay-black-middle" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<div className="container">
							<div className="dlab-bnr-inr-entry">
								<h1 className="text-white">Manage Your Online Appointment System Now</h1>
								<br />
								<p>You can bring the online appointment feature to your business through Appomark.</p>
								<p>Login to your Appomark panel now and activate your online appointment feature.</p>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		)
	}
}

export default HomePage;